import {getCSpotSession} from '~/composables/auth/useAuth'
import type {CSPotSession} from '~/types/user'

export default defineNuxtRouteMiddleware(async () => {
  const session: CSPotSession = getCSpotSession()

  if (!session || !session.user) {
    return navigateTo('/landing')
  }
})
